export default {
  COLOR: {
    black: '#000',
    white: '#fff',
  },
  GRADIENT: {
  },
  THEME: {
    document: {
      '@light': {
        color: 'black',
        background: 'white',
      },
      '@dark': {
        color: 'white',
        background: 'black',
      },
    },
    none: {
      color: 'none',
      background: 'none',
    },
    transparent: {
      color: 'currentColor',
      background: 'transparent',
    },
  },
  FONT: {
  },
  FONT_FAMILY: {
    def: {
      isDefault: true,
      value: [
        '--system-default',
      ],
      type: 'sans-serif',
    },
  },
  TYPOGRAPHY: {
    base: 16,
    ratio: 1.25,
    subSequence: true,
    templates: {
      h1: {
      },
      h2: {
      },
      h3: {
      },
      h4: {
      },
      h5: {
      },
      h6: {
      },
      body: {
      },
    },
  },
  SPACING: {
    base: 16,
    ratio: 1.618,
    subSequence: true,
  },
  TIMING: {
  },
  CLASS: {
  },
  GRID: {
  },
  ICONS: {
  },
  SHAPE: {
  },
  RESET: {
  },
  ANIMATION: {
  },
  MEDIA: {
  },
  CASES: {
  },
  useReset: true,
  useVariable: true,
  useFontImport: true,
  useIconSprite: true,
  useSvgSprite: true,
  useDefaultConfig: true,
  useDocumentTheme: true,
  verbose: false,
};