export const Avatar = {
  extend: [
    'Img',
  ],
  props: ({
        key: r,
        props: p
      }) => ({
        src: `https://api.dicebear.com/7.x/${p.avatarType||"adventurer-neutral"}/svg?seed=${p.key||r||"no-avatar"}`,
        display: "block",
        boxSize: "C+Z1",
        avatarType: "adventurer-neutral",
        round: "100%"
      }),
};