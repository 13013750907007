export const main = {
  props: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  H1: {
    text: 'Yes to Europe, No to Russian Law',
  },
};